.content {
  position: relative;
  width: 100%;
}

.input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  user-select: none;
  cursor: pointer;
  text-align: left;
}
.label::before {
  content: "";
  display: block;
  width: 10px;
  min-width: 10px;
  height: 10px;
  border: 1px solid var(--color-checkbox-border);
  border-radius: 2px;
  background-repeat: no-repeat;
  background-position: center center;
}

input:checked + .label::before {
  border-color: var(--btn-bgc);
  background-color: var(--btn-bgc);
  background-image: url(../../../images/checkbox.svg);
}
